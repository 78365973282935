import React from 'react';
import PropTypes from 'prop-types';
import * as cx from 'classnames';

export default function ProfileCard(props) {
  return (
    <div className={cx('ProfileCard', props.className)}>
      <div className="ProfileCard__top">
        <img className="ProfileCard__img" src={props.image} alt={props.title} loading="lazy" />
        <div className="ProfileCard__text">
          <span className="ProfileCard__title">
            {props.title}
          </span>
          {props.subtitle && <span className="ProfileCard__subtitle">{props.subtitle}</span>}
          <span className="ProfileCard__description">{props.description}</span>
        </div>
      </div>
      {props.body && (
        <div className="ProfileCard__body">
          {props.body}
        </div>
      )}
      {props.cta}
    </div>
  );
}

ProfileCard.propTypes = {
  className: PropTypes.string,
  body: PropTypes.elementType,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
};

ProfileCard.defaultProps = {
  className: null,
  subtitle: null,
  body: null,
};
