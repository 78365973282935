import React from 'react';
import PropTypes from 'prop-types';

import {Grid, Row, Col} from './Grid';
import Link from './Link';

export const ProductOverview = (props) => (
  <div className="ProductOverview py-16">
    <h3 className="text-center text-base text-indigo font-bold uppercase tracking-wider">{props.lead}</h3>
    <h2 className="mx-auto mt-2 mb-6 max-w-5xl text-center text-2xl md:text-4xl text-black font-black">{props.heading}</h2>
    <Grid className="mx-auto max-w-5xl flex justify-center items-center">
      <Col sm={10}>
        {(props.products || []).map((f) => (
          <Row className="py-5 items-center">
            <Col sm={5} className="flex flex-col md:pr-12" key={f.name}>
              <span className="text-xl md:text-2xl text-black font-semibold my-1">
                {f.name}
              </span>
              <span className="text-base text-gray-500">
                {f.description}
              </span>
              <Link href={f.ctaUrl} className="block py-6">
                <span className="text-lg text-indigo font-semibold">{`${f.ctaText} →`}</span>
              </Link>
            </Col>
            <Col sm={5} className="pl-0 pr-0 flex items-center justify-center rounded-xl bg-white border-gray-200 border-2 shadow-sm overflow-hidden">
              <img src={f.image} alt="" loading="lazy" />
            </Col>
          </Row>
        ))}
      </Col>
    </Grid>
  </div>
);

ProductOverview.propTypes = {
  lead: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.any,
    image: PropTypes.element,
    ctaUrl: PropTypes.string,
    ctaText: PropTypes.string,
  })).isRequired,
};

export default ProductOverview;
