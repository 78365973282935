import React, {useEffect} from 'react';

import {useAnalytics} from '../hooks';
import Button, {ButtonSizes} from '../components/ButtonNew';
import UniversityBar from '../components/HomepageUniversityBar';
import CompanyBar from '../components/HomepageCompanyBar';
import {TestimonialSection} from '../components/TestimonialSection';
import {ProductOverview} from '../components/ProductOverview';
import HomepageHero from '../components/HomepageHero';
import {TracksOverview} from '../components/TracksOverview';
import DocumentHeader from '../components/DocumentHeader';
import getContent from '../util/landingPageConfig';

export const CtaSection = ({text, href, cta}) => (
  <div className="px-4 py-12">
    <h2 className="text-center text-xl md:text-2xl font-bold text-black">{text}</h2>
    <div className="flex justify-center mt-6">
      <Button size={ButtonSizes.LARGE} href={href} className="w-full sm:w-auto">{cta}</Button>
    </div>
  </div>
);

const HomeLanding = () => {
  const {tracker} = useAnalytics();

  useEffect(() => {
    tracker.homepageView();
  }, []);

  const content = getContent('new_landing_page');

  return (
    <div className="bg-white">
      <DocumentHeader title="Interview prep for product, engineering, data science, and more" />
      <HomepageHero
        heading={content.landingSub}
        ctaText={content.landingCtaText}
        ctaUrl={content.landingCtaUrl}
      />
      <TracksOverview
        lead="Our Courses"
        heading="What are you preparing for?"
      />
      <div className="mt-4 bg-gray-50">
        <CompanyBar />
      </div>
      <ProductOverview
        lead={content.productOverview.lead}
        heading={content.productOverview.heading}
        products={Object.values(content.productOverview.productsList)}
      />
      <TestimonialSection />
      <div className="hidden lg:block">
        <UniversityBar />
      </div>
      <CtaSection href="/signup" cta="Create your free account ->" text="Ready to get started?" />
    </div>
  );
};

export default HomeLanding;
