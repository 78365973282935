import React from 'react';
import PropTypes from 'prop-types';
import {ChevronRightIcon} from '@heroicons/react/outline';
import {SparklesIcon} from '@heroicons/react-v2/16/solid';
import cx from 'classnames';

import Button, {ButtonSizes, ButtonTypes} from './ButtonNew';
import {useAnalytics} from '../hooks';
import Link from './Link';

const GradientText = ({children}) => (
  <span className="bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent" style={{backgroundClip: 'text'}}>
    {children}
  </span>
);

const AnnouncementLink = ({
  href,
  text,
  className,
}) => (
  <Link
    href={href}
    className={cx('group px-2 py-1.5 border border-gray-200 hover:bg-white hover:border-gray-300 rounded-full inline-flex items-center text-gray-500 text-xs font-medium transition duration-25', className)}
  >
    <SparklesIcon className="w-4 h-4 mr-2 text-indigo-500" />
    {text}
    <ChevronRightIcon className="text-gray-400 group-hover:text-gray-500 h-3 w-3 ml-1" />
  </Link>
);


export const Hero = (props) => {
  const {tracker} = useAnalytics();

  const onCtaClickHandler = () => {
    tracker.landingClickViewCourses();
  };

  return (
    <div className="container px-8 py-8 md:pt-20 bg-white">
      <div className="mx-auto max-w-5xl flex flex-col md:flex-row justify-center items-center">
        <div className="flex flex-col items-center">
          <AnnouncementLink href="/practice?src=homepage" text="Introducing Exponent Practice" className="mb-2" />
          <h1 className="mt-2 md:mt-4 text-3xl md:text-7xl text-center text-gray-900 font-bold tracking-tight leading-none">
            Everything you need
            <br />
            to
            <GradientText> ace </GradientText>
            your tech interviews
          </h1>
          <h3 className="mx-auto mt-4 mb-6 max-w-4xl text-lg md:text-xl text-center text-gray-600 font-regular leading-tight tracking-tight">
            Level up your career and land your next role with
            courses, mock interviews, and community.
          </h3>
          <div className="mt-0 w-full flex justify-center gap-3">
            <Button
              size={ButtonSizes.LARGE}
              onClick={onCtaClickHandler}
              href={props.ctaUrl}
              className="w-full sm:w-auto"
            >
              Get started for free
              <span className="ml-1.5 opacity-50">{'->'}</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

Hero.propTypes = {
  ctaUrl: PropTypes.string.isRequired,
};

export default Hero;
