import React from 'react';
import PropTypes from 'prop-types';

const companies = [
  {name: 'Facebook/Meta', src: '/images/companies/meta.png'},
  {name: 'Google', src: '/images/companies/google.png'},
  {name: 'Microsoft', src: '/images/companies/microsoft.png'},
  {name: 'Amazon', src: '/images/companies/amazon.png'},
  {name: 'OpenAI', src: '/images/companies/openai.png'},
  {name: 'Airbnb', src: '/images/companies/airbnb.png'},
  {name: 'Tiktok', src: '/images/companies/tiktok.png'},
  {name: 'Stripe', src: '/images/companies/stripe.png'},
];

const CompanyBar = ({items = companies}) => (
  <div className="max-w-7xl mx-auto px-8 py-12 bg-gray-50">
    <div className="mb-2 text-center text-sm text-gray-600 font-medium">
      Join a community of 500K+ candidates who&rsquo;ve landed jobs at top companies
    </div>
    <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-8 gap-4">
      {items && items.length > 0 && items.map((item) => (
        <div key={item.name} className="col-span-1 flex justify-center items-center md:col-span-1 lg:col-span-1">
          <img
            alt={item.name}
            src={item.src}
            className="h-16 w-24 object-contain filter grayscale opacity-50"
            loading="lazy"
            style={{mixBlendMode: 'darken'}}
          />
        </div>
      ))}
    </div>
  </div>
);

export default CompanyBar;
