import React from 'react';

import HomeLanding from '../js/pages/HomeLanding';
import DashboardPage from '../js/pages/DashboardPage';
import PageContainer from '../js/components/PageContainer';
import {useAppContext} from '../js/hooks';

export default function Page() {
  const {currentUser} = useAppContext();

  return currentUser
    ? <DashboardPage />
    : <HomeLanding />;
}

Page.getLayout = (page) => (
  <PageContainer stickyHeader>
    {page}
  </PageContainer>
);
