import React from 'react';
import PropTypes from 'prop-types';

const universities = [
  {name: 'Stanford Graduate School of Business', src: '/images/schools/gsb.png'},
  {name: 'Columbia Engineering', src: '/images/schools/columbia_engineering.png'},
  {name: 'Cornell Johnson Business School', src: '/images/schools/cornell.png'},
  {name: 'Yale School of Management', src: '/images/schools/yale_som.png'},
  {name: 'Rochester Simon Business School', src: '/images/schools/rochester_simon.png'},
  {name: 'UVA Darden School of Business', src: '/images/schools/uva_darden.jpg'},
  {name: 'Michigan Ross School of Business', src: '/images/schools/ross.png'},
  {name: 'Duke Fuqua Business School', src: '/images/schools/duke_fuqua.jpg'},
];

const UniversityBar = ({items = universities}) => (
  <div className="container mx-auto py-10">
    <div className="mb-6 text-center text-base text-gray-600 font-medium">
      Trusted by leading universities and organizations
    </div>
    <div className="grid grid-cols-2 gap-10 md:grid-cols-4 lg:grid-cols-8">
      {items && items.length > 0 && items.map((item) => (
        <div key={item.name} className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
          <img
            alt={item.name}
            src={item.src}
            className="w-32 h-20 object-scale-down filter grayscale"
            loading="lazy"
            style={{mixBlendMode: 'darken'}}
          />
        </div>
      ))}
    </div>
  </div>
);

export default UniversityBar;
